export const Close = () => {
  return (
    <svg
      className="icon icon--close"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m.703125 21.7959375 21.09375-21.09375" />
      <path d="m21.796875 21.7959375-21.09375-21.09375" />
    </svg>
  )
}
