import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { zoomImageOnMouseClick } from '../helpers.ts'
import { CaretBottom } from '../icons/CaretBottom.tsx'
import marked from './marked.ts'

export const ResultLinks = (props: {
  linksResult: string
  maybeBadRating: boolean
  quickFeedback: ReactNode
  otherModelButton: boolean
}) => {
  const { t } = useTranslation()
  const [showRefs, setShowRefs] = useState(false)

  return (
    <>
      {props.linksResult && props.linksResult !== '__NO_LINKS__' && (
        <>
          <div className="references">
            <div
              onClick={zoomImageOnMouseClick}
              className={`resultBoxLinks ${showRefs ? 'resultBoxLinks--all' : ''}`}
              dangerouslySetInnerHTML={{
                // keep using marked directly instead of MyMarkdown because we need to be able to set directly HTML from the backend, which MyMarkdown doesn't allow
                __html: marked(`${props.linksResult}`, {
                  headerIds: false,
                  mangle: false,
                }),
              }}
            />
            <div className="references__footer">
              <button onClick={() => setShowRefs(!showRefs)} className="button">
                {showRefs ? t('answers.hideRefs') : t('answers.showRefs')}
                <CaretBottom rotate={showRefs} />
              </button>
            </div>
          </div>
        </>
      )}
      {props.linksResult && <>{props.quickFeedback}</>}
    </>
  )
}
