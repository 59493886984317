export const Like = () => {
  return (
    <svg
      className="icon icon--like"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m4.996875 7.899375h-4.21875v12.65625h4.21875z" />
      <path d="m4.9978125 19.1446875c4.2675 0 4.6153125 1.6996875 10.3921875 2.0465625 1.621875.0975 2.78625.1809375 3.8671875-1.20375 2.371875-3.0421875 3.511875-10.276875 1.2103125-10.276875h-4.700625a1.875 1.875 0 0 1 -1.875-1.875v-3.646875c0-2.83125-4.141875-4.3171875-4.141875-1.14375 0 2.27625-.8671875 3.84-2.315625 5.2190625-.70875.6740625-1.5375 1.035-2.4365625 1.228125" />
    </svg>
  )
}
