import { Dispatch, SetStateAction } from 'react'

import { UsedLinks } from '../helpers.ts'
import AudioPlayerWrapper from './AudioPlayerWrapper.tsx'
import MyMarkdown from './Markdown.tsx'
import { ResultErz } from './ResultErz.tsx'

export const Result = (props: {
  queryResult: string
  query: string
  erzFunction: { query: string; function: string } | null
  isLoading: boolean
  userId: string | null
  setLinksResult: Dispatch<SetStateAction<string>>
  usedLinksResult: UsedLinks[]
}) => {
  const queryParams = new URLSearchParams(window.location.search)

  const DO_AUDIO_BUTTON = !!queryParams.get('audio')

  if (props.queryResult === '') {
    return null
  }
  const hasFunctionResult = props.erzFunction?.function && props.erzFunction.function.length > 0
  return (
    <>
      {hasFunctionResult && <ResultErz erzQuery={props.erzFunction} userId={props.userId} />}
      <div className="resultBox" translate="no">
        {hasFunctionResult && (
          <p>
            <em>Originale Antwort:</em>
          </p>
        )}
        <MyMarkdown
          markdown={
            props.queryResult +
            (props.isLoading
              ? ` ![](${window.location.hostname === 'localhost' ? '/src' : ''}/assets/icons/loader.svg)`
              : '')
          }
          usedLinks={props.usedLinksResult}
        ></MyMarkdown>
        {DO_AUDIO_BUTTON && !props.isLoading && props.queryResult && <AudioPlayerWrapper text={props.queryResult} />}
      </div>
    </>
  )
}
