import { Trans, useTranslation } from 'react-i18next'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className="footer">
      <Trans i18nKey="footer.credits">
        Powered by
        <a href={'https://www.liip.ch'} target={'_blank'}>
          Liip
        </a>
        —
        <a
          href={'https://www.liip.ch/blog/ask-zuricitygpt-anything-about-the-government-of-the-city-of-zurich'}
          target={'_blank'}
        >
          Blog Post about the Project
        </a>
      </Trans>
      &emsp;
      {t('footer.disclaimer')}
    </footer>
  )
}
