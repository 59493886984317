import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'

import { ArrowRight } from '../icons/ArrowRight.tsx'
import { SpeechButton } from './SpeechButton.tsx'
import { VisionButton } from './VisionButton.tsx'

export const SearchForm = (props: {
  setInputValue: Dispatch<SetStateAction<string>>
  setIsLoading: Dispatch<SetStateAction<boolean>>

  inputValue: string
  sendSubmit: (value: string) => void
  submitDisabled: boolean
}) => {
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)

  const DO_SPEECH_BUTTON = !!queryParams.get('speech')
  const DO_VISION_BUTTON = !!queryParams.get('vision')

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.setInputValue(event.target.value)
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (props.submitDisabled) {
      return
    }
    props.sendSubmit(props.inputValue)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit(event)
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit} className="question">
        <label htmlFor="question" className="sr-only">
          {t('question.label')}
        </label>
        <TextareaAutosize
          autoFocus
          maxRows={3}
          className="question__input"
          id="question"
          value={props.inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={t('question.placeholder')}
        />
        {DO_VISION_BUTTON && (
          <VisionButton
            setIsLoading={props.setIsLoading}
            setInputValue={props.setInputValue}
            sendSubmit={props.sendSubmit}
          />
        )}
        {DO_SPEECH_BUTTON && <SpeechButton setInputValue={props.setInputValue} />}
        <button
          type="submit"
          tabIndex={-1}
          className="question__button button button--no-wrap"
          disabled={props.inputValue.length === 0}
        >
          <span>{t('question.buttonText')}</span>
          <ArrowRight />
        </button>
      </form>
    </>
  )
}
