export const CaretBottom = (props: { rotate?: boolean }) => {
  return (
    <svg
      className={`icon icon--caret ${props.rotate ? 'icon--180' : ''}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline points="3.2 7.7 11.6 16.1 19.9 7.7" />
    </svg>
  )
}
