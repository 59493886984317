import { TFunction } from 'i18next'
import isMobileDevice from 'ismobilejs'
import { Dispatch, SetStateAction } from 'react'

export enum DEFAULT_SHORT_NAME {
  MODEL_3_5_4k = '3.5-4k',
  MODEL_3_5_16k = '3.5-16k',
  MODEL_4_0_8k = '4-8k',
}

export interface UsedLinks {
  url: string
  title?: string
  type?: string
  image?: string
  date?: string
  description?: string
}
//export const isMobile = true
export const isMobile = window ? isMobileDevice(window?.navigator).any : false

export const zoomImageOnMouseClick = (e: React.MouseEvent<HTMLDivElement>) => {
  const clickedImageWrapper = e.target as HTMLElement

  if (clickedImageWrapper.classList.contains('reference__image')) {
    clickedImageWrapper.classList.toggle('is-zoomed')
  }
}

export const replaceLinksWithCallback = (
  input: string,
  usedLinks: UsedLinks[],
  setResult: Dispatch<SetStateAction<string>>,
) => {
  const linkMatches = input.match(/\[\^([0-9]+)\^*]/g)
  if (linkMatches) {
    setResult(replaceLinks(input, usedLinks))
  }
}

export const replaceLinks = (input: string, usedLinks: UsedLinks[]) => {
  const linkMatches = input.match(/\[\^([0-9]+)\^*]/g)
  if (linkMatches) {
    let newInput = input

    linkMatches.forEach(match => {
      const linkNumber = match.match(/([0-9]+)/g)?.[0]

      if (!linkNumber) {
        return
      }
      const link = linkNumber && usedLinks[parseInt(linkNumber) - 1]
      if (link) {
        newInput = newInput.replace(match, ` ([Link ${linkNumber}](${link.url} "__link: ${linkNumber}"))`)
      } else {
        // if we don't have a link, remove the link
        newInput = newInput.replace(match, ``)
      }
    })
    return newInput
  }
  return input
}

export function setEventSource(
  setUri: string,
  t: TFunction<'translation', undefined>,
  setLinksResult: (value: ((prevState: string) => string) | string) => void,
  setUsedLinksInfoResult: (value: ((prevState: UsedLinks[]) => UsedLinks[]) | UsedLinks[]) => void,
  setInfoResult: (value: ((prevState: string) => string) | string) => void,
) {
  try {
    if (!setUri) {
      return
    }

    const ev = new EventSource(setUri)

    ev.onerror = e => {
      setLinksResult(queryResult =>
        queryResult.concat(`

${t('answers.serverConnectionError')}`),
      )
      console.log(e)
      ev.close()
    }
    ev.onmessage = event => {
      const parsedData = JSON.parse(event.data)
      if (parsedData.links) {
        setLinksResult(linksResult => {
          return linksResult.concat(parsedData.links)
        })
      }
      if (parsedData.usedLinks) {
        setUsedLinksInfoResult(linksResult => {
          return linksResult.concat(parsedData.usedLinks)
        })
      }

      if (parsedData.response) {
        if (parsedData.response === '__THIS_IS_THE_ANSWER_END__') {
          ev.close()

          return
        }
        if (parsedData.response === '__CLR__') {
          setInfoResult('')
          return
        }
        setInfoResult(queryResult => {
          return queryResult.concat(parsedData.response)
        })
      }
    }

    return () => {
      ev.close()
    }
  } catch (error) {
    console.log(error)
  }
}
