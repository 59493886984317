export const Hint = () => {
  return (
    <svg
      className="icon icon--hint"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m14.0625 16.171875v4.21875a1.40625 1.40625 0 0 1 -1.40625 1.40625h-2.8125a1.40625 1.40625 0 0 1 -1.40625-1.40625v-4.21875" />
      <path d="m8.4375 18.984375h5.625" />
      <path d="m11.25.703125v1.40625" />
      <path d="m1.40625 10.546875h1.40625" />
      <path d="m3.515625 3.515625 1.03125.99375" />
      <path d="m21.09375 10.546875h-1.40625" />
      <path d="m18.984375 3.515625-1.03125.99375" />
      <path d="m8.4375 10.078125a1.40625 1.40625 0 0 1 1.40625 1.40625v4.6875" />
      <path d="m12.65625 16.171875v-4.6875a1.40625 1.40625 0 0 1 1.40625-1.40625" />
      <path d="m8.3625 16.171875h5.775a6.1875 6.1875 0 0 0 3.440625-5.625 6.440625 6.440625 0 0 0 -6.328125-6.328125 6.440625 6.440625 0 0 0 -6.328125 6.328125 6.1875 6.1875 0 0 0 3.440625 5.625z" />
    </svg>
  )
}
