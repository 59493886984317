export const ArrowRight = () => {
  return (
    <svg
      className="icon icon--bold icon--arrow-right"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m6 12h12.5m0 0-6-6m6 6-6 6" />
    </svg>
  )
}
