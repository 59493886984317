import { MutableRefObject, RefObject, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowRight } from '../icons/ArrowRight.tsx'
import { Dislike } from '../icons/Dislike.tsx'
import { Like } from '../icons/Like.tsx'
import { Regenerate } from '../icons/Regenerate.tsx'
import GlobalContext from './GlobalContext.tsx'

export const QuickFeedback = (props: {
  questionRef: MutableRefObject<null>
  requestId?: string | null
  userId?: string | null
  inputValue: string
  openDrawer: string | null
  changeModel: () => void
  handleMenuItemClick: (drawerName: string | ((prevState: string | null) => string | null)) => void
}) => {
  const { t } = useTranslation()
  const [wasUseful, setwasUseful] = useState(false)
  const [wasNotUseful, setwasNotUseful] = useState(false)
  const { host, org } = useContext(GlobalContext)

  const setFocusOnQuestion = (questionRef: RefObject<HTMLElement>) => {
    questionRef.current?.querySelector('textarea')?.focus()
    questionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleFeedback = async (feedback: string) => {
    try {
      if (feedback === 'positive') {
        setwasUseful(true)
        setwasNotUseful(false)
      } else {
        setwasUseful(false)
        setwasNotUseful(true)
      }

      setTimeout(() => {
        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' })
      }, 200)

      const body: Record<string, string> = {
        feedback,
        rID: props.requestId || '',
      }

      await fetch(`${host}/${org}/quickfeedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
    } catch (error) {
      console.log('Error sending feedback data:', error)
    }
  }

  return (
    <div className="box" id={'quickfeedback'}>
      <div className="box__content">
        <button type="submit" onClick={props.changeModel} className="button button--model">
          <span>{t('answers.otherModelButton')}</span>
          <Regenerate />
        </button>
        <div className="box__is-useful">
          <span>{t('answers.wasItUseful')}</span>
          <button
            className="button button--small"
            onClick={() => {
              handleFeedback('positive')
            }}
          >
            <span>{t('answers.yes')}</span>
            <Like />
          </button>
          <button
            className="button button--small"
            onClick={() => {
              handleFeedback('negative')
            }}
          >
            <span>{t('answers.no')}</span>
            <Dislike />
          </button>
        </div>
      </div>
      <div className={`box__drawer ${wasUseful ? 'box__drawer--is-open' : ''}`}>
        <div className="box__drawer__content">
          <div className="h5">{t('answers.thanksForFeedback')}</div>
        </div>
      </div>
      <div className={`box__drawer ${wasNotUseful ? 'box__drawer--is-open' : ''}`}>
        <div className="box__drawer__content">
          <div className="h5">{t('answers.letsTryToImprove')}</div>
          <div className="improvements">
            <button className="button" onClick={() => setFocusOnQuestion(props.questionRef)}>
              <span>{t('answers.improvePrompt')}</span>
              <ArrowRight />
            </button>
            <button className="button" onClick={() => props.handleMenuItemClick('hints')}>
              <span>{t('answers.readHints')}</span>
              <ArrowRight />
            </button>
            <button className="button" onClick={() => props.handleMenuItemClick('feedback')}>
              <span>{t('answers.giveFeedback')}</span>
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
