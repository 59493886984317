import { FancyChip } from '../FancyChip.tsx'
import { Logo } from '../LogoZH.tsx'

export const Illustration = () => {
  return (
    <div className="header__illustration">
      <div className="illustration">
        <div className="illustration__logo">
          <Logo />
        </div>
        <div className="illustration__background">
          <FancyChip />
        </div>
      </div>
    </div>
  )
}
