export const Megaphone = () => {
  return (
    <svg
      className="icon icon--megaphone"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m6.328125 14.296875h-2.109375a3.515625 3.515625 0 0 1 0-7.03125h2.109375z" />
      <path d="m6.328125 14.296875a20.90625 20.90625 0 0 1 11.593125 3.51l1.063125.70875v-15.46875l-1.063125.70875a20.90625 20.90625 0 0 1 -11.593125 3.51z" />
      <path d="m21.796875 9.375v2.8125" />
      <path d="m6.328125 14.296875a6.7865625 6.7865625 0 0 0 2.109375 4.921875" />
    </svg>
  )
}
