export const Regenerate = () => {
  return (
    <svg
      height="24"
      className="icon icon--regenerate"
      viewBox="-.75 -.75 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m17.214375 6.5625a7.734375 7.734375 0 0 0 -13.69875 4.9190625v2.1121875" />
        <path d="m5.625 16.78875a7.734375 7.734375 0 0 0 13.359375-5.3071875v-1.40625" />
        <path d="m.703125 10.7784375 2.8125 2.8125 2.8125-2.8125" />
        <path d="m21.796875 12.8878125-2.8125-2.8125-2.8125 2.8125" />
      </g>
    </svg>
  )
}
